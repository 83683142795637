<template>
  <div class="form">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="information" service="refMortgage" backlink="/refinance-mortgage/type" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent="submitHandler">
            <p class="promo promo_blocks2">{{ "rm_s4p17_text_1" | localize }}</p>
            <div class="form__group">
              <v-text-field
                v-model="decrease_money"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @change="checkForm($v.decrease_money)"
                @blur="$v.decrease_money.$touch()"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('decrease_money', decrease_money)"
                :class="{invalid: $v.decrease_money.$dirty && !$v.decrease_money.required }"
                :hint="($v.decrease_money.$dirty && !$v.decrease_money.required ? 'form_reqiered' : 'empty') | localize"
                :placeholder="'rm_s4p16_placeholder_1' | localize"
                append-icon="icon-₪"
                ref="decrease_money"
                outlined
              ></v-text-field>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

const refMortgage = VueCookies.get('refMortgage') || {}

export default {
  name: 'Refinance_mortgage_decrease',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin],
  data: () => ({
    decrease_money: refMortgage.decrease_money || null,
    amount: null
  }),
  validations: {
    decrease_money: { required }
  },
  created () {
    this.decrease_money = refMortgage.decrease_money ? this.formatNumber(refMortgage.decrease_money) : null
  },
  methods: {
    submitHandler () {
      this.decrease_money = this.parseNumber(this.decrease_money)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      this.success = true
      refMortgage.decrease_money = this.decrease_money

      this.$cookies.set('refMortgage', refMortgage)

      this.$store.commit('loading', true)
      const userId = this.$store.state.user ? this.$store.state.user.id : 0
      this.axios.get('user_service', {
        params: {
          user_id: userId,
          service_id: this.$store.state.services.refMortgage
        }
      }).then((response) => {
        const userServiceId = response.data.id || 0
        const amount = parseInt(response.data.amount, 10) - parseInt(this.decrease_money, 10)
        this.axios.put('user_service/' + userServiceId, {
          user_id: userId,
          service_id: this.$store.state.services.refMortgage,
          status: this.$store.state.service_status,
          amount: amount
        }).then(() => {
          this.$store.commit('loading', false)
          this.$router.push('/refinance-mortgage/something-else')
        }).catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
      }).catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }
  }
}
</script>
